<script>
import { mapState } from 'vuex';
import PFrom from '@/components/reservationForm/pc_from/pc_from';
import MFrom from '@/components/reservationForm/h5_from/h5_from';

export default {
	components: {},
	name: 'reservationForm',
	data() {
		return {};
	},
	components: {
		[PFrom.name]: PFrom,
		[MFrom.name]: MFrom
	},
	watch: {},
	render(createElement) {
		if (this.isPc) {
			// return createElement('pc_from');
			this.$router.push({ name: 'informationFilling', params: { type: 'pre' } })
		}
		return createElement('h5_from');
	},
	created() {},
	mounted() {},
	computed: {
		isPc() {
			return this.$store.state.isPc;
		}
	},
	computed: {
		...mapState(['isPc'])
	}
};
</script>

<style lang="scss" scoped="scoped"></style>
