<template>
	<div class="wrapper" v-show="viewShow">
		<section class="wrapper_top">
			<img :src="require('@/assets/image/fh2.png')" alt="" @click="fh()" />
			考生信息填寫
		</section>
		<section class="wrapper_centent">
			<div class="uls">
				<div class="lis lis_hr">
					<span>證件姓名</span>
					<div class="rightInput">
						<input type="text" v-model="from.name" placeholder="請輸入證件姓名" @blur="onBlurInput()" />
					</div>
				</div>
				<div class="lis lis_hr">
					<span>證件英文名</span>
					<div class="rightInput">
						<input type="text" v-model="from.enName" placeholder="請輸入證件英文名" @blur="onBlurInput()" />
					</div>
				</div>
				<div class="lis lis_hr">
					<span>手機號</span>
					<div class="rightInput">
						<country class="country" :countryCode.sync="from.countryCode"></country>
						<input
							type="text"
							v-model="from.phoneNumber"
							placeholder="請輸入手機號"
							@input="from.phoneNumber = Number($event.target.value.replace(/\D+/, ''))"
							@blur="onBlurInput()"
						/>
					</div>
				</div>
				<div class="lis lis_hr">
					<span>手機驗證碼</span>
					<div class="rightInput">
						<div class="lls" style="width: 100%;">
							<input type="text" v-model="from.phoneCode" placeholder="請輸入驗證碼" @blur="onBlurInput()" />
						</div> 
						<div class="inputButCode" @click="CodeBln ? clickNumberCodeFnc() : ''">{{ NumberCodeText }}</div>
					</div>
				</div>
				<div class="lis lis_hr">
					<span>電郵</span>
					<div class="rightInput"><input type="text" v-model="from.mall" placeholder="請輸入電郵" @blur="onBlurInput()" /></div>
				</div>
				<div class="lis">
					<span>預約时间</span>
					<div class="rightInput">
						<div class="inputButTime" @click="subscribe()">{{ lijiyuy }}</div>
					</div>
				</div>
			</div>

			<div class="uls">
				<div class="lis lis_hr">
					<span>證件類型</span>
					<div class="rightInput">
						<div class="Picker" :style="from.certificate == '' ? 'color: #999999' : 'color: #000;'" @click="certificateShow = true">
							{{ from.certificate == '' ? '請選擇證件' : from.certificate }}
						</div>
					</div>
				</div>
				<div class="lis">
					<span>證件號</span>
					<div class="rightInput"><input type="text" v-model="from.certificateValue" placeholder="請輸入證件號" @blur="onBlurInput()" /></div>
				</div>
			</div>
		</section>

		<div class="fromBut" @click="fromBut">提交信息</div>
		<!-- 选择身份信息 -->
		<van-popup v-model="certificateShow" position="bottom" :style="{ height: '30%' }">
			<van-picker title="" show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel" @change="onChange" :item-height="rem2('60')">
				<template v-slot:confirm>
					<div class="popup_quedin">確定</div>
				</template>
				<template v-slot:cancel>
					<div class="popup_quedin">取消</div>
				</template>

				<template v-slot:option="data">
					<div class="popup_quedin">{{ data }}</div>
				</template>
			</van-picker>
		</van-popup>
		<!-- 选择预约时间 -->
		<van-overlay :lock-scroll="false" :show="timeShow" @click="timeShow = false" class="popupTyShow">
			<div class="popup_timeBox" @click.stop>
				<img :src="require('@/assets/image/guanbi.png')" alt="" class="guanbi" @click="timeShow = false" />
				<div class="time_title">預約時間</div>
				<div class="time_Uls" ref="timeSlide">
					<div
						class="list"
						v-for="(item, index) in weekOptions"
						:key="index"
						:class="timeWeeSelect == index ? 'listSelect' : ''"
						:ref="`scrolltab${index}Ref`"
						@click="timeWeeSelect != index ? tabClick(index) : ''"
					>
						<p>{{ item.name }}</p>
						<p>{{ item.time }}</p>
						<img class="disimg" :src="require('@/assets/image/xuanzhe.png')" alt="" v-if="timeWeeSelect == index ? true : false" />
					</div>
				</div>

				<!-- <strong v-for="(xyy, zyy) in weekOptions" :key="zyy" v-if="timeWeeSelect == zyy"> -->
				
				<div v-if="timeCount != 0" class="strong_boxBlack" id="listScroll" ref="listScroll">
					<div class="title_times">上午</div>
					<div class="time_UlsTwo">
						<div
							class="list"
							v-for="(item, index) in TimePeriods"
							:key="index"
							@click="item.leftNum==0 ? '' :  item.isValid === 0 ? timeBut(item) : ''"
							:class="item.leftNum==0 ? 'listRet' : item.isOpt ? 'listSelect' : item.isValid === 0 ? '' : 'listRet'"
							v-if="item.timeFrame == 'AM'"
						>
							<p>{{ item.testStartTime }}-{{ item.testEndTime }}</p>
							<p>剩餘：{{ item.leftNum }}</p>
							<img class="disimg" :src="require('@/assets/image/xuanzhe.png')" alt="" v-if="item.isOpt" />
						</div>
					</div>
					<div class="title_times">下午</div>
					<div class="time_UlsTwo">
						<div
							class="list"
							v-for="(item, index) in TimePeriods"
							:key="index"
							@click="item.leftNum==0 ? '' :  item.isValid === 0 ? timeBut(item) : ''"
							:class="item.leftNum==0 ? 'listRet' : item.isOpt ? 'listSelect' : item.isValid === 0 ? '' : 'listRet'"
							v-if="item.timeFrame == 'PM'"
						>
							<p>{{ item.testStartTime }}-{{ item.testEndTime }}</p>
							<p>剩餘：{{ item.leftNum }}</p>
							<img class="disimg" :src="require('@/assets/image/xuanzhe.png')" alt="" v-if="item.isOpt" />
						</div>
					</div>
				</div>

				<div class="koyy" v-else>
					<img :src="require('@/assets/image/sadx.png')" alt="" />
					<span>當前日期已約滿，請選擇其他日期</span>
				</div>
				<!-- </strong> -->

				<div class="yyButtom" @click="yyButtom">確定</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
import { mapState } from 'vuex';  
import country from '@/components/reservationForm/country.vue';
import api from '@/api/index'; 
import moment from 'moment';
export default {
	components: {},
	name: 'h5_from',
	data() {
		return {
			NumberCodeText: '點擊發送',
			CodeBln: true,
			timeOut: null,
			from: {
				name: null, //姓名
				phoneNumber: null, //手机号
				countryCode: Number(852), //区号
				phoneCode: null, //验证码
				mall: null, //邮箱
				certificate: '', //证件
				certificateIndex: null, //证件id
				certificateValue: null, //证件号
				selectTime:null, //测试时间obj
				enName:null,//证件英文名
			},
			certificateShow: false,
			columns: ['身份證', '護照'], //0,1
			timeShow: false, //预约时间
			timeCount: 0, //记录总数经常变 
			timeWeeSelect: 0,
			lijiyuy: '立即預約',
			weekOptions: [], //第一个时间
			TimePeriods: [] //课程
		};
	},
	watch: {},
	components: { country },
	created() {},
	mounted() { 
	}, 
	computed: { 
		 ...mapState(['isPc','viewShow']),
		viewShow(){
		  return this.$store.state.viewShow;
		}
	},
	methods: {
		subscribe() {
			//预约按钮
			console.log(this.weekOptions);
			if (this.weekOptions.length == 0) {
				this.weekOptions = [];
				let time = new Date();
				for (let i = 0; i < 7; i++) {
					let obj = {};
					let day = moment(moment(time).add(i+2, 'd')).day();
					obj.time = moment(time)
						.add(i+2, 'd')
						.format('MM月DD日');
					obj.YTD = moment(time)
						.add(i+2, 'd')
						.format('YYYY-MM-DD');
					obj.name = this.getWeek(day);
					this.weekOptions.push(obj);
				}
				this.timeShow = true;
				this.handleSelectDate(this.weekOptions[this.timeWeeSelect].YTD);
			} else {
				this.timeShow = true;
				this.handleSelectDate(this.weekOptions[this.timeWeeSelect].YTD);
			}
		},
		getWeek(day) {
			let weekName = '星期日';
			switch (parseInt(day)) {
				case 1:
					weekName = '星期一';
					break;
				case 2:
					weekName = '星期二';
					break;
				case 3:
					weekName = '星期三';
					break;
				case 4:
					weekName = '星期四';
					break;
				case 5:
					weekName = '星期五';
					break;
				case 6:
					weekName = '星期六';
					break;
			}
			return weekName;
		},
		// 时间
		handleSelectDate(time) {
			console.log(time);
			// 每次进来先初始化
			this.TimePeriods = [];
			this.timeCount = 0;

			this.$toast.loading({
				forbidClick: true,
				overlay: true,
				duration: 0
			});

			api.getAppointments(time)
				.then(res => {
					this.$toast.clear();

					console.log(res);
					if (res.code == 0) {
						let zs = 0;
						this.TimePeriods = res.rows.map(item => {
							let val = item.testStartTime.split(':')[0];
							// 当允许的时候计算个数  如果为0那么就显示当前预约已满
							if(item.isValid===0){
								this.timeCount = this.timeCount + item.leftNum;
							} 
							
							item['isOpt'] = false;
							if (Number(val) < 12) {
								item['timeFrame'] = 'AM';
							} else {
								item['timeFrame'] = 'PM';
							}
							return item;
						});
					}
				})
				.catch(error => {
					this.$toast.clear();
					console.log(error);
				});
		},
		// 手机
		clickNumberCodeFnc() {
			this.$toast.loading({
				forbidClick: true,
				overlay: true,
				duration: 0
			});
			const { phoneNumber, countryCode } = this.from;
			let regPhone = countryCode === 86 ? /^1[3456789]\d{9}$/ : /^[0-9]*$/;

			if (!phoneNumber) {
				this.$toast('請輸入正確的手機號');
				return false;
			}

			if (!regPhone.test(phoneNumber)) {
				this.$toast('請檢查是否輸入');
				return false;
			}

			clearInterval(this.timeOut);
			this.CodeBln = false;
			let index = 60;
			let phoneValue = countryCode === 86 ? phoneNumber : '+' + countryCode + '-' + phoneNumber;
			const phoneList = [];
			phoneList.push('+' + countryCode + '-' + phoneNumber);

			let params = {
				appType: 12,
				verifyType: 9,
				phoneList,
				smsTemplateType: 10,
				signType: 1
			};
			console.log('打印传参:::', params);

			api.sendVerificationCode(params)
				.then(res => {
					this.$toast.clear();
					console.log(res);
					if (res.code == 0) {
						this.timeOut = setInterval(() => {
							index--;
							if (index <= 0) {
								index = 60;
								this.CodeBln = true;
								this.NumberCodeText = '獲取驗證碼';
								clearInterval(this.timeOut);
							} else {
								this.NumberCodeText = `${index}s`;
							}
						}, 1000);
					} else {
						this.$toast(res.msg)
						this.CodeBln = true;
					}
				})
				.catch(error => {
					this.$toast.clear();
					this.$toast('獲取失敗,請重新獲取');
					this.NumberCodeText = '重新獲取';
					this.CodeBln = true;
				});
		},
		// 处理ios软键盘弹出页面被顶上去兼容问题
		onBlurInput() {
			window.scroll(0, 0);
		},
		fh() {
			this.$dialog
				.confirm({
					title: '提示',
					message: '當前添加或修改的數據未提交，返回將會丟失，是否確定返回?'
				})
				.then(() => {
					// on confirm
					history.go(-1); //返回首页
				})
				.catch(() => {
					// on cancel
				});
		},
		yyButtom() {
			this.timeWeeSelect = 0;
			this.timeShow = false;
			this.TimePeriods = [];
		},
		timeBut(e) {
			console.log(e);
			if (e.is_valid > 0) {
				return;
			}
			this.TimePeriods.map(i => {
				if (e.testStartTime == i.testStartTime) {
					i.isOpt = true;
					const { testDate, testStartTime, testEndTime } = i;
					this.from.selectTime = i;
					this.lijiyuy = testDate.split(' ')[0] + ' ' + testStartTime + '-' + testEndTime;
				} else {
					i.isOpt = false;
				}
				return i;
			});
		},
		// 滑动
		scrollLeftTo(name) {
			const ref = `scrolltab${name}Ref`;
			console.log('ref',ref,'name',name)
			const nav = this.$refs.timeSlide;
			const title = this.$refs[ref][0]; 
			// 计算位移偏差
			const to = title.offsetLeft - (nav.offsetWidth - title.offsetWidth) / 2; 
			nav.scrollLeft = to;
			
		},
		tabClick(e,name) {
			console.log('e',e)
			this.timeWeeSelect = e;
			// 触发滑动方法
			if(this.$refs.listScroll != undefined){
				let ls = this.$refs.listScroll.scrollTop = 0;
			}
			
			this.scrollLeftTo(e,name);
			this.handleSelectDate(this.weekOptions[this.timeWeeSelect].YTD);
		},
		handleClick() {
			// 防止事件冒泡的
		},
		rem2(e) {
			let res = e / 75;
			console.log('resss', res);
			return res + 'rem';
		},
		onConfirm(value, index) {
			this.from.certificateIndex = index;
			this.from.certificate = value;
			this.certificateShow = false;
		},
		onChange(picker, value, index) {
			this.from.certificateIndex = index;
			this.from.certificate = value;
		},
		onCancel() {
			this.$toast('取消');
			this.certificateShow = false;
		},
		// 提交
		fromBut() {  
			
			const { name,enName, countryCode, phoneNumber, phoneCode, mall, certificateIndex, certificateValue, selectTime } = this.from;
			let regPhone = countryCode === 86 ? /^1[3456789]\d{9}$/ : /^[0-9]*$/;
			let regEmail = /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-\_]+\.)+[A-Za-z]{2,6}$/;
			if (!name || !name.trim()) {
				this.$toast('請輸入證件姓名');
				return false;
			}else if (!enName || !enName.trim()) {
				this.$toast('請輸入證件英文名');
				return false;
			}  else if (!phoneNumber) {
				this.$toast('請輸入手機號碼');
				return false;
			} else if (!regPhone.test(phoneNumber)) {
				this.$toast('手機號碼有誤，請重新輸入');
				return false;
			} else if (!phoneCode) {
				this.$toast('驗證碼不能為空');
				return false;
			} else if (!mall) {
				this.$toast('請輸入電郵');
				return false;
			} else if (!regEmail.test(mall.trim())) {
				this.$toast('電郵格式有誤，請重新輸入');
				return false;
			} else if (certificateIndex === null) {
				this.$toast('請選擇證件類型');
				return false;
			} else if (!certificateValue) {
				this.$toast('請輸入證件號碼');
				return false;
			} else if (!selectTime) {
				this.$toast('請選擇預約时间');
				return false;
			}
			 
			this.$dialog
				.confirm({
					title: '確定信息',
					message: '是否確定你的個人信息無誤?一旦確定無法修改信息.'
				})
				.then(() => {
					 const { testDate, testStartTime, testEndTime } = selectTime
					 const params = {
					 	testName: name.trim(),
					 	phone:phoneNumber,
					 	verifyCode:phoneCode,
					 	email: mall.trim(),
					 	idType:certificateIndex,
					 	idNo:certificateValue,
					 	areaCode: countryCode,
					 	testDate,
					 	testStartTime,
					 	testEndTime,
					 	testNamePinyin:enName,
					   }
					 console.log(params)
					 
					 // this.$router.push('/reservationFormState');
					 this.$toast.loading({
					 	message: '提交中...',
					 	forbidClick: true,
					 	overlay: true,
					 	duration: 0
					 })
					 
					 api.saveAppointments(params)
					 	.then(res => {
					 		this.$toast.clear();
					 		console.log(res);
					 		if (res.code == 0) {
					 			 this.$router.replace({ name: 'reservationFormState', params: {data:res.data} })
					 		} else { 
					 			this.$toast(res.msg)
					 		}
					 	})
					 	.catch(error => {
					 		this.$toast.clear(); 
					 	});
				})
				.catch(() => {
					// on cancel
				});
			
		}
	}
};
</script>

<style lang="scss">
@import '@/assets/css/index.scss';
// 改写部分vant  Dialog弹出框
.van-dialog {
	width: px2rem(640);
	font-size: px2rem(32);
}
.van-dialog__message {
	padding: px2rem(52) px2rem(48);
	font-size: px2rem(28);
	line-height: px2rem(40);
}
.van-dialog__header {
	padding-top: px2rem(52);
	line-height: px2rem(58);
}
.van-dialog__cancel,
.van-dialog__confirm {
	height: px2rem(96);
}
.van-button {
	font-size: px2rem(32);
}
.van-hairline--top::after {
	border-top-width: px2rem(4);
}
.van-hairline--left::after {
	border-left-width: px2rem(4);
}
.van-picker__toolbar{
	height: px2rem(100) !important;
}
.van-loading__spinner {
	width: px2rem(100) !important;
	height: px2rem(100) !important;
}
.van-toast {
	width: auto;
}
</style>

<style lang="scss" scoped="scoped">
@import '@/assets/css/index.scss';


.wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	overflow-x: hidden;
	background: #fafafa;
	padding-top: px2rem(80);
	box-sizing: border-box;

	.wrapper_top {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: px2rem(80);
		text-align: center;
		line-height: px2rem(80);
		font-size: px2rem(36);
		background: #ffffff;
		font-family: PingFang SC-Semibold, PingFang SC !important;
		font-weight: 600;
		color: #000000;

		img {
			width: px2rem(56);
			height: px2rem(56);
			position: absolute;
			left: px2rem(32);
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}

	.wrapper_centent {
		width: 100%;
		padding: px2rem(32);
		box-sizing: border-box;

		.uls {
			width: 100%;
			background: #ffffff;
			padding-left: px2rem(32);
			padding-right: px2rem(32);
			box-sizing: border-box;
			margin-bottom: px2rem(18);
			border-radius: px2rem(16);

			.lis {
				width: 100%;
				height: px2rem(104);
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: px2rem(28);
				font-family: PingFang SC-Regular, PingFang SC; 

				span {
					font-size: px2rem(28);
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
					white-space: nowrap;
				}

				.rightInput {
					display: flex;
					align-items: center;

					.country {
						width: px2rem(100);
						position: relative;
						text-align: center;
						padding-right: px2rem(32);
					}

					.country::after {
						content: ' ';
						width: px2rem(2);
						height: 100%;
						background: #f2f2f2;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0%;
						margin: auto;
					}
					
					.lls{ 
						padding-right: px2rem(32);
						box-sizing:  border-box;
						width: 100%;
						input{
							width:100%;
						}
					}
					.inputButCode {
						font-size: px2rem(28);
						font-family: PingFang SC-Regular, PingFang SC;
						// margin-left: px2rem(32);
						// padding-left: px2rem(32);
						box-sizing: border-box;
						font-weight: 400;
						color: #122ab0;
						position: relative;
						white-space: nowrap;
					}

					.inputButCode::after {
						content: ' ';
						width: px2rem(2);
						height: 100%;
						background: #f2f2f2;
						position: absolute;
						top: 0;
						left: -15%;
						bottom: 0%;
						margin: auto;
					}

					.inputButTime {
						font-size: px2rem(28);
						font-family: PingFang SC-Regular, PingFang SC;
						box-sizing: border-box;
						font-weight: 400;
						color: #122ab0;
						white-space: nowrap;
					}

					input {
						border: none;
						padding: 0px;
						text-align: right;
					}
					input:focus {
						outline: none !important;
					}
					input::-webkit-input-placeholder {
						color: #999999;
					}
					/* 使用webkit内核的浏览器 */
					input:-moz-placeholder {
						color: #999999;
					}
					/* Firefox版本4-18 */
					input::-moz-placeholder {
						color: #999999;
					}
					/* Firefox版本19+ */
					input:-ms-input-placeholder {
						color: #999999;
					} /* IE浏览器 */
				}
			}

			.lis_hr {
				position: relative;
			}
			.lis_hr::after {
				content: ' ';
				width: 100%;
				height: px2rem(2);
				background: #f2f2f2;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0%;
				margin: auto;
			}
		}
	}

	.fromBut {
		width: calc(100% - #{px2rem(32)} - #{px2rem(32)});
		position: fixed;
		bottom: 3%;
		left: 0;
		right: 0;
		padding-left: px2rem(32);
		padding-right: px2rem(32);
		box-sizing: border-box;
		height: px2rem(88);
		line-height: px2rem(88);
		background: #122ab0;
		border-radius: px2rem(92);
		margin: auto;
		font-size: px2rem(32);
		font-family: PingFang SC-Semibold, PingFang SC !important;
		font-weight: 600;
		color: #ffffff;
		box-shadow: 0px px2rem(8) px2rem(8) 0px rgba(18, 42, 176, 0.3);
	}

	.popup_quedin {
		font-size: px2rem(32);
		height: px2rem(100);
		line-height:px2rem(100);
	}

	.popupTyShow {
		display: flex;
		justify-content: center;
		align-items: center;

		.popup_timeBox {
			width: calc(100% - #{px2rem(32)} - #{px2rem(32)});
			border-radius: px2rem(16);
			padding-top: px2rem(48);
			padding-bottom: px2rem(48);
			box-sizing: border-box;
			background: #fff;
			position: relative;

			.guanbi {
				width: px2rem(20);
				height: px2rem(20);
				position: absolute;
				right: px2rem(38);
				top: px2rem(38);
			}

			.time_title {
				text-align: center;
				font-size: px2rem(36);
				font-family: PingFang SC-Semibold, PingFang SC;
				font-weight: 600;
				color: #333333;
				line-height: px2rem(42);
			}

			.time_Uls {
				display: flex;
				overflow-x: scroll;
				scroll-behavior: smooth; //平稳的滑动效果
				padding-left: px2rem(32);
				padding-right: px2rem(32);
				box-sizing: border-box;
				margin-top: px2rem(60);

				.list {
					width: px2rem(188);
					height: px2rem(132);
					line-height: px2rem(28);
					border-radius: px2rem(16);
					text-align: center;
					font-weight: 400;
					color: #ffffff;
					cursor: pointer;
					flex: 1 0 auto;
					text-align: center;
					position: relative;
					font-size: px2rem(24);
					font-weight: 400;
					padding-top: px2rem(24);
					box-sizing: border-box;
					margin-right: px2rem(24);
					position: relative;
					overflow: hidden;
					border: px2rem(2) solid #333333;

					p:nth-child(1) {
						color: #262626;
						font-family: PingFang SC-Regular, PingFang SC;
						margin-bottom: px2rem(8);
					}
					p:nth-child(2) {
						color: #666666;
						font-family: PingFang SC-Regular, PingFang SC;
					}

					.disimg {
						position: absolute;
						right: 0;
						bottom: 0;
						width: px2rem(48);
						height: px2rem(34);
					}
				}

				.listSelect {
					background: #f0f3ff;
					border: px2rem(2) solid #122ab0 !important;
				}
			}

			.time_Uls::-webkit-scrollbar {
				display: none;
			}

			.strong_boxBlack {
				width: 100%;
				height: px2rem(800);
				overflow: scroll;
				display: block; 
			}
			.strong_boxBlack::-webkit-scrollbar {
			    display: none;
			}

			.title_times {
				width: 100%;
				padding-left: px2rem(32);
				padding-right: px2rem(32);
				box-sizing: border-box;
				text-align: left;
				font-size: px2rem(32);
				font-family: PingFang SC-Semibold, PingFang SC;
				font-weight: 600;
				color: #333333;
				line-height: px2rem(38);
				margin-top: px2rem(56);
			}

			.time_UlsTwo {
				display: flex;
				padding-left: px2rem(32);
				padding-right: px2rem(32);
				box-sizing: border-box;
				margin-top: px2rem(32);
				flex-wrap: wrap;
				justify-content: space-between;

				.list {
					width: px2rem(188);
					height: px2rem(132);
					line-height: px2rem(28);
					border-radius: px2rem(16);
					text-align: center;
					font-weight: 400;
					color: #ffffff;
					cursor: pointer;
					// flex: 1 0 auto;
					text-align: center;
					position: relative;
					font-size: px2rem(24);
					font-weight: 400;
					padding-top: px2rem(24);
					box-sizing: border-box;
					// margin-right: px2rem(24);
					margin-bottom: px2rem(32);
					position: relative;
					overflow: hidden;
					border: px2rem(2) solid #333333;

					p:nth-child(1) {
						color: #262626;
						font-family: PingFang SC-Regular, PingFang SC;
						margin-bottom: px2rem(8);
					}
					p:nth-child(2) {
						color: #122ab0;
						font-family: PingFang SC-Regular, PingFang SC;;
					}

					.disimg {
						position: absolute;
						right: 0;
						bottom: 0;
						width: px2rem(48);
						height: px2rem(34);
					}
				}

				.stl_p {
					width: px2rem(188);
				}

				.listRet {
					border: 2px solid #999999 !important;

					p {
						color: #999999 !important;
					}
				}

				.listSelect {
					background: #f0f3ff;
					border: px2rem(2) solid #122ab0 !important;
				}
			}

			.koyy {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;

				img {
					width: px2rem(396);
					height: px2rem(300);
					margin-top: px2rem(88);
				}

				span {
					width: 100%;
					margin-top: px2rem(32);
					font-size: px2rem(28);
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
					line-height: px2rem(33);
				}
			}

			.yyButtom {
				width: calc(100% - #{px2rem(32)} - #{px2rem(32)});
				height: px2rem(88);
				line-height: px2rem(88);
				background: #122ab0;
				border-radius: px2rem(92);
				font-size: px2rem(32);
				font-family: PingFang SC-Semibold, PingFang SC;
				font-weight: 600;
				color: #ffffff;
				margin: auto;
				margin-top: px2rem(64);
			}
		}
	}
}
</style>
