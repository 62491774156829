<template>
	<div class="wrapper" v-cloak>
	 
	</div>
</template>

<script> 
export default {
	components: {},
	name: 'pc_from',
	data() {
		return {};
	}, 
	watch: {},
	created() {},
	mounted() {},
	computed: {},
	methods: {}
};
</script>

<style lang="scss" scoped="scoped">
@import '@/assets/css/index.scss';


.wrapper {
	width: 750px; 
	margin: auto;
	position: relative; 
	background: linear-gradient(309deg, #132bb0 0%, #1934cb 100%);
	padding: pcFn(60) pcFn(32);
	box-sizing: border-box;
 
}
</style>
